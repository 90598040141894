'use client'

import type Rollbar from 'rollbar'
import { createContext, useContext } from 'react'
import type { PropsWithChildren } from 'react'

declare global {
  // eslint-disable-next-line no-var, vars-on-top
  var Rollbar: Rollbar | undefined
}

export const RollbarContext = createContext<Rollbar | undefined>(undefined)

interface RollbarProviderProps extends PropsWithChildren {
  client?: Rollbar | undefined
}

export function RollbarProvider({ children, client }: RollbarProviderProps) {
  let rollbar = client

  if (typeof window !== 'undefined') {
    rollbar = rollbar ?? globalThis.Rollbar
  }

  return (
    <RollbarContext.Provider value={rollbar}>
      {children}
    </RollbarContext.Provider>
  )
}

export function useRollbar() {
  const rollbar = useContext(RollbarContext)

  return rollbar
}

type Person = NonNullable<Rollbar.Payload['person']>

export function useRollbarPerson(person: Person) {
  const rollbar = useRollbar()
  rollbar?.configure({ payload: { person } })
}
