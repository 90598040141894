import { get, set, del } from 'idb-keyval'
import type { Persister } from '@tanstack/react-query-persist-client'

/**
 * Creates an Indexed DB persister
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 */
export function createIDBPersister(idbValidKey: IDBValidKey = 'reactQuery') {
  return {
    persistClient(client) {
      return ignoreError(set(idbValidKey, client))
    },
    restoreClient() {
      return ignoreError(get(idbValidKey))
    },
    removeClient() {
      return ignoreError(del(idbValidKey))
    },
  } satisfies Persister
}

/**
 * Catches and ignores errors
 *
 * Some browsers disable IndexedDB in incognito or private browsing mode.
 * Interacting with Indexed DB in these modes will throw an error.
 * This function catches and ignores those errors.
 *
 * @param promise the promise to ignore
 * @returns resolves with undefined
 */
async function ignoreError<T>(promise: Promise<T>) {
  let result

  try {
    result = await promise
  } catch {
    /* empty */
  }

  return result
}
