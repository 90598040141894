import { extendTheme } from '@chakra-ui/react'
import { modalTheme } from './modal'

const overrides = {
  colors: {
    brand: {
      500: '#365F9D',
      600: '#365F9D',
    },
  },
  components: {
    Button: {
      defaultProps: {
        colorScheme: 'brand',
      },
    },
    Modal: modalTheme,
  },
}

export default extendTheme(overrides)
