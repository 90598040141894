'use client'

import { Component } from 'react'
import type { ErrorInfo, PropsWithChildren } from 'react'
import type Rollbar from 'rollbar'
import { RollbarContext } from './rollbar-context'

interface ErrorBoundaryProps extends PropsWithChildren {
  fallbackUI?: React.ComponentType<{ error: unknown; onResetError: () => void }>
}

interface State {
  error: unknown
  hasError: boolean
}

const INITIAL_ERROR_STATE = { hasError: false, error: null }

export class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  static override contextType = RollbarContext

  // TODO uncomment this when storybook supports typescript declare syntax
  // declare context: ContextType<typeof RollbarContext>

  constructor(props: PropsWithChildren) {
    super(props)
    this.state = INITIAL_ERROR_STATE
  }

  static getDerivedStateFromError(error: unknown) {
    return { hasError: true, error }
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const rollbar = this.context as Rollbar | undefined

    // eslint-disable-next-line no-console
    console.error(error)
    rollbar?.error(error, errorInfo)
  }

  handleResetError = () => {
    this.setState(INITIAL_ERROR_STATE)
  }

  override render() {
    const { hasError, error } = this.state
    const { fallbackUI: FallbackUI, children } = this.props

    if (!hasError) {
      return children
    }

    if (!FallbackUI) {
      return null
    }

    return <FallbackUI error={error} onResetError={this.handleResetError} />
  }
}
