export interface SerializedRequest {
  id: string | undefined
  method: string
  url: string
  headers: Record<string, string>
}

/**
 * Creates a serialized request object to be logged by pino
 *
 * @param request the request
 * @returns the serialized request
 */
export function req(request?: Request): SerializedRequest | undefined {
  if (request === undefined) {
    return request
  }

  return {
    headers:
      request.headers instanceof Headers
        ? Object.fromEntries(request.headers)
        : request.headers,
    id: '1',
    method: request.method,
    url: request.url,
  }
}
