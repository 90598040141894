import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const config = createMultiStyleConfigHelpers(parts.keys)

const baseStyle = config.definePartsStyle({
  header: {
    background: 'brand.500',
    color: 'white',
  },
})

export const modalTheme = config.defineMultiStyleConfig({
  baseStyle,
})
