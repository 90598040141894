export interface SerializedResponse {
  url: string
  status: number
  headers: Record<string, string>
}

/**
 * Creates a serialized response object to be logged by pino
 *
 * @param response the response
 * @returns the serialized response
 */
export function res(response?: Response): SerializedResponse | undefined {
  if (response === undefined) {
    return response
  }

  return {
    url: response.url,
    status: response.status,
    headers:
      response.headers instanceof Headers
        ? Object.fromEntries(response.headers)
        : response.headers,
  }
}
