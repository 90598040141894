import pino from 'pino'
import type { LoggerOptions } from 'pino'
import { prettyLog } from './pretty'
import * as serializers from './serializers'

let destination: pino.DestinationStream = process.stdout

if (process.env['NODE_ENV'] !== 'production') {
  destination = {
    write(object) {
      // eslint-disable-next-line no-console
      console.log(...prettyLog(JSON.parse(object)))
    },
  }
}

/**
 * Creates an isomorphic logger that can be used on the server and the client.
 */
export function createLogger(options?: LoggerOptions) {
  return pino(
    {
      ...options,
      serializers,
    },
    destination,
  )
}
