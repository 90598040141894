'use client'

import type { PropsWithChildren } from 'react'
import { createContext, useContext } from 'react'
import invariant from 'tiny-invariant'
import type { Logger } from '.'

const childLoggers = new Map<string, Logger>()

export const LoggerContext = createContext<Logger | null>(null)

/**
 * Use an instance of logger from LoggerContext
 *
 * Creates a named child instance when a name is provided
 *
 * @returns an logger instance
 */
export const useLogger = (name?: string) => {
  const logger = useContext(LoggerContext)

  invariant(logger !== null, 'LoggerContext is not provided')

  if (typeof name === 'string') {
    let childLogger = childLoggers.get(name)

    if (childLogger === undefined) {
      childLogger = logger.child({ name })
      childLoggers.set(name, childLogger)
    }

    return childLogger
  }

  return logger
}

type LoggerProviderProps = PropsWithChildren<{ logger: Logger }>

/**
 * Provides a named logger instance to LoggerContext
 */
export function LoggerProvider({ children, logger }: LoggerProviderProps) {
  return (
    <LoggerContext.Provider value={logger}>{children}</LoggerContext.Provider>
  )
}
